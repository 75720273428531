require('./global');

$(document).on('focus', 'input[placeholder]', function(evt) {
	if ($(this).attr('placeholder').trim() !== '') {
		$(this).attr('data-placeholder', $(this).attr('placeholder'));
		$(this).removeAttr('placeholder');
	}
});
$(document).on('blur', 'input[data-placeholder]', function(evt) {
	if ($(this).attr('data-placeholder').trim() !== '') {
		$(this).attr('placeholder', $(this).attr('data-placeholder'));
		$(this).removeAttr('data-placeholder');
	}
});
$(function() {
	setTimeout(function () {   window.scrollTo(0, 1); }, 0);
});